<style lang="scss" scoped>
.person {
  @apply w-full space-y-4 flex flex-col justify-between;
  padding: 24px;
  // border: 1px solid red;
  margin-bottom: 20px;
  // height: 400px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 100%,
      rgba(255, 255, 255, 0) 100%
    );
  color: white;
  text-align: left;
  border-width: 2px;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1)
  );
  @apply flex flex-col items-start relative;
  &:after {
    background: radial-gradient(
      60.32% 62.28% at 49.86% 100%,
      rgba(255, 196, 82, 0.088) 0%,
      rgba(229, 255, 68, 0) 100%
    );
    content: '';
    @apply absolute w-full h-full inset-0;
  }
  .content {
    @apply w-full space-y-4 flex flex-col flex-grow;
  }
  h1 {
    // font-family: Blender Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
  }
  .text {
    @apply flex-grow;
    font-size: 18px;
    line-height: 120% !important;
    opacity: 0.9;
    color: white !important;
  }
  .tags {
    @apply flex flex-row flex-wrap w-full;
    .tag {
      opacity: 0.7;
      border-radius: 4px;
      margin-right: 8px;
      margin-bottom: 8px;
      font-size: 14px;
      border: 1px solid #ffffff;
      padding: 5px 10px;
    }
  }
  a {
    // @apply self-end;
    // bottom: 20px;
    cursor: pointer !important;
    z-index: 999999;
  }
  .button {
    @apply font-sbsans-ui flex items-center justify-center w-full text-white rounded-full transition-colors;
    font-size: 18px;
    max-width: 300px;
    padding: 8px 30px;
    background-color: #2b9a4a;

    &:hover {
      background-color: #0f6a28;
    }

    &:focus {
      background-color: #0f6a28;
    }

    &:active {
      opacity: .7;
    }

    &--outline {
      background-color: transparent;
      border: 1px solid white;

      &:hover {
        background-color: rgba(#2b9a4a, 0.1);
      }

      &:focus {
        background-color: rgba(#2b9a4a, 0.1);
      }
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
@screen sm {
  .person {
    max-width: calc(50% - 10px);
  }
}
</style>

<template>
  <div class="person">
    <div class="content">
      <h1>{{ position }}</h1>
      <p class="text">{{ description }}</p>
      <div class="tags">
        <div v-for="tag in tags" :key="tag" class="tag">{{ tag }}</div>
      </div>
    </div>
    <div class="flex">
      <a class="button" :href="url" target="_blank"
        >Смотреть<icon-play class="ml-2 mt-1"
      /></a>
      <a :href="telegram" class="button button--outline" target="_blank">
        <icon-telegram class="mr-2" />Связаться
      </a>
    </div>
  </div>
</template>

<script>
import IconPlay from '../Icons/IconPlay.vue';
import IconTelegram from '../Icons/IconTelegram.vue';
export default {
  name: 'CardVacancy',
  components: { IconPlay, IconTelegram },
  data() {
    return {};
  },
  props: {
    position: { type: String },
    description: { type: String },
    tags: { type: Array },
    url: { type: String },
    telegram: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
