export const CONF_SETTINGS = {
  id: 'devoops',
  logo: '/images/confs/devOps.svg',
  blurColor: 'purple',
  question: {
    title: "Оцените, сколько разработчиков инфраструктуры используют платформу Kubernetes?",
    answers: [
      { text: "Около 20%", isTrue: false },
      { text: "Около 40%", isTrue: true },
      { text: "Около 60%", isTrue: false },
      { text: "Её уже не используют", isTrue: false }
    ],
    successText: "Да, именно так, в 2018 году ее использовали 16% разработчиков, в 2019-м — 29%, а в 2020-м — целых 40%",
    failureText: "А вот и нет! В 2018 году ее использовали 16% разработчиков, в 2019-м — 29%, а в 2020-м — целых 40%",
  },
  awarding: {
    date: '11 Ноября',
    tableClose: "19:00 — Закроем турнирную таблицу",
    winnersTime: "19:30 — Объявим победителей",
    positions: '1-5 место',
  },
  title: "Только настоящий девопсер победит в этой схватке!",
  vacancies: [
    {
      position: 'DevOps-инженер',
      description: 'Ищем в команду SberDevices DevOps. Важно: знание Kubernetes/OpenShift, Ansible и опыт разработки Pipeline CI/CD. Работа по Agile. Тебя ждут масштабные задачи и создание уникального продукта: умных устройств с виртуальными ассистентами.',
      url: 'https://syktyvkar.hh.ru/vacancy/45674132',
      tags: ["Kubernetes", 'OpenShift', 'Ansible', 'Pipeline CI/CD', 'Agile'],
      telegram: 'https://t.me/SvetlanaSberDevices',
    },
    {
      position: 'DevOps-инженер',
      description: 'В  команде SberDevices есть тёплое местечко для DevOps-инженера! Оно твоё, если имеешь опыт эксплуатации Kubernetes, Helm, Docker, Nginx, Bitbucket/GitLab, Jenkins, Ansible. Приходи к нам развивать информационно-аналитическую систему расчёта и мониторинга ключевых результатов компании.',
      url: 'https://syktyvkar.hh.ru/vacancy/48404565',
      tags: ["Kubernetes", "Helm", "Docker", "Nginx", "Bitbucket", "GitLab", "Jenkins", "Ansible"],
      telegram: 'https://t.me/SvetlanaSberDevices',
    },
    {
      position: 'DevOps-инженер',
      description: 'SberDevices в поиске DevOps на поддержку текущей инфраструктуры CI/CD, мы ждем тебя если тебе знакомы эти магические слова: Linux, GIT, Docker, Ansible',
      url: 'https://hh.ru/vacancy/48907695',
      tags: ['CI/CD', "Linux", "GIT", "Docker", "Ansible"],
      telegram: 'https://t.me/SvetlanaSberDevices',
    },
    {
      position: 'DevOps-инженер',
      description: 'Команда развития Planform V ищет DevOps-специалиста и единомышленника. Интересные задачи на масштабном проекте с современным стеком технологий. Если ты знаешь: Ansible, Docker, k8s и Jenkins, тебе точно к нам!',
      url: 'https://nn.hh.ru/vacancy/49058168',
      tags: ["Ansible", "Docker", "k8s", "Jenkins"],
      telegram: 'https://t.me/xResident',
    },
    {
      position: 'DevOps-инженер',
      description: 'Ищем DevOps-инженера в команду КиберМетодологии. Если ты настраивал CI/CD с нуля, работал с Jenkins, а Ansible, Docker для тебя не пустой звук, то нам есть что тебе предложить. Работа по Agile, амбициозные задачи и самая лучшая команда ждут тебя.',
      url: 'https://hh.ru/vacancy/48701377',
      tags: ['CI/CD', 'Jenkins', "Ansible", "Docker", 'Agile'],
      telegram: 'https://t.me/springsun28',
    },
    {
      position: 'DevOps-инженер',
      description: 'Ждем в команду DevOps-инженера. Наши сервисы построены на платформе Сбера с использованием микросервисной архитектуры и современных тех. стеков Jenkins, Nexus, Ansible, Docker, OpenShift/Kubernetes. Работать можно в офисе и удаленно в Москве и Санкт-Петербурге.',
      url: 'https://hh.ru/vacancy/48527312',
      tags: ["Jenkins", "Nexus", "Ansible", "Docker", "OpenShift", "Kubernetes"],
      telegram: 'https://t.me/ShMaria',
    },
    {
      position: 'DevOps-инженер',
      description: 'Ищем DevOps-инженера для развития сервисов зарплатных проектов. Наши  продукты позволят клиентам банка заключать/изменять/расторгать зарплатные договоры, выпускать карты в рамках зарплатных договоров и проводить выплаты денежных средств.',
      url: 'https://hh.ru/vacancy/49194385',
      tags: ["DevOps", "Docker"],
      telegram: 'https://t.me/shamraysvetlana',
    },
    {
      position: 'DevOps-инженер',
      description: 'Команда СберИнвестора, разрабатывает передовую брокерскую платформу. Мы выводим нашего брокера на новый уровень, не поддерживаем legacy и полностью переосмысливаем все старые процессы. Если Вы DevOps который понимает архитектуру современных автоматизированных систем / приложений, способов интеграции систем, а также работал с системами Task-tracking, контейнерами: Docker, Openshift и инструментами непрерывного развёртывания ПО Jenkins, Ansible, Bitbucket и т.п. конечно куда же без Linux. Мы рады принять тебя в свою команду.',
      url: 'https://hh.ru/vacancy/48883858',
      tags: ["Docker", "Openshift", "Jenkins", "Ansible", "Bitbucket", "Linux"],
      telegram: 'https://t.me/Konstantin_SBER_IT',
    },
    {
      position: 'DevOps-инженер',
      description: 'SberData - специализированное подразделение компании, которое занимается разработкой, развитием и сопровождением различных сервисов, продуктов, инструментов на основе передовых технологий в мире Big Data. Специально для тебя современный стек — BitBucket, Ansible, Jenkins, Zabbix, Grafana, Kafka, Python/Java. И классный проект по оптимизации процессов хранения и работе с данными, в рамках которой планируется реализовать централизованный сервис по управлению жизненным циклом данных.',
      url: 'https://hh.ru/vacancy/48934934',
      tags: ["BitBucket", "Ansible", "Jenkins", "Zabbix", "Grafana", "Kafka", "Python/Java"],
      telegram: 'https://t.me/hello_intelligence',
    },
  ]
}
