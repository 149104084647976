<style lang="scss"></style>

<template>
  <div class="w-full flex flex-col md:flex-row flex-wrap justify-between">
    <CardVacancy v-for="(item, idx) in vacancies" :key="idx" v-bind="item" />
  </div>
</template>

<script>
import { CONF_SETTINGS } from '@/confSettings';
import CardVacancy from '@/components/Global/CardVacancy.vue';

export default {
  name: 'ListVacancies',
  components: { CardVacancy },
  data() {
    return {
      vacancies: CONF_SETTINGS.vacancies,
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
